





























































import Vue from "vue";
import Clipboard from "clipboard";
import { mapState } from "vuex";
import { Toast } from "vant";
import { dispatch } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return { balance: 0 };
  },
  computed: {
    ...mapState(["profile"]),
  },
  methods: {
    onCoinClick() {
      window.open(url.miaocoin);
    },
    onIdClick() {
      const clipboard = new Clipboard(".id");
      clipboard.on("success", () => {
        Toast("用户 ID 已复制");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        Toast("用户 ID 复制失败");
        clipboard.destroy();
      });
    },
  },
  mounted() {
    dispatch.profileGetBalance().then((res) => {
      this.balance = res.sum;
    });
  },
});
